@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  border: 4px solid #fff;
}

::-webkit-scrollbar-thumb:hover {
  background: #546ccc;
}

/* react-toastify */
:root {
  --toastify-toast-min-height: 48px;
  --toastify-color-success: #FF6E30;
}

.Toastify__toast-icon {
  width: 14px;
}

/* nprogress */
#nprogress {
  left: 0;
  top: 0;
  z-index: 9999;
  position: fixed;
  height: 100%;
  width: 100%;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}
#nprogress .bar {
  top: 0;
  left: 0;
  z-index: 9999;
  position: fixed;
  height: 4px;
  width: 100%;
  background: #29d;
}
#nprogress .spinner {
  display: inline-flex;
  width: 80px;
  height: 80px;
}

#nprogress .spinner .spinner-icon {
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
